<script>
import feather from 'feather-icons';
import ProjectsFilter from './ProjectsFilter.vue';
import ProjectSingle from './ProjectSingle.vue';
import projects from '../../data/projects';

export default {
	components: { ProjectSingle, ProjectsFilter },
	data: () => {
		return {
			projects,
			projectsHeading: 'Projects Portfolio',
			selectedCategory: '',
			searchProject: '',
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-10 sm:pt-14">
		<!-- Projects grid title -->
		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
				{{ projectsHeading }}
			</p>
		</div>

		<!-- Filter and search projects -->
		<div class="mt-5 sm:mt-10">
			<ProjectsFilter @filter="selectedCategory = $event" />  
			<h3 class="font-general-regular mt-5
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				">
				Search projects by title or filter by category
			</h3>
			<div class="
					flex
					border-b border-primary-light
					dark:border-secondary-dark
					pb-3
					gap-1
				">
				<div class="flex gap-2">
					<span class="
							hidden
							sm:block
							bg-primary-light
							dark:bg-ternary-dark
							p-2
							shadow-sm
							rounded-xl
							cursor-pointer
							">
							<i data-feather="search" class="text-ternary-dark dark:text-ternary-light"></i>
						</span>
						<input v-model="searchProject" class="font-general-medium
						pl-1
						pr-1
						sm:px-1
						py-2
						border-1 border-gray-200
						dark:border-secondary-dark
						rounded-lg
						text-sm
						sm:text-md
						bg-secondary-light
						dark:bg-ternary-dark
						text-primary-dark
						dark:text-ternary-light
						" id="name" name="name" type="search" required="" placeholder="Search Projects" aria-label="Name" />
					</div>
				</div>
			</div>
			
			<!-- Projects grid -->
			<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10">
				<ProjectSingle v-for="project in filteredProjects" :key="project.id" :project="project" />
			</div>
		</section>
		
</template>

<style scoped></style>
