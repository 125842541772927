import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/app.css';
import BackToTop from 'vue-backtotop';
import './registerServiceWorker'

const feather = require('feather-icons');
feather.replace();

createApp(App)
	.use(router)
	.use(BackToTop)
	.mount('#app');

	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.register("sw.js").then(registration => {
			console.log("SW Registered!");
			console.log(registration);
		}).catch(error => {
			console.log("Faild Registered!");
			console.log(error);
		})
	}



const appTheme = localStorage.getItem('theme');

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
	appTheme === 'dark' &&
	document.querySelector('body').classList.contains('app-theme')
) {
	document.querySelector('body').classList.add('bg-primary-dark');
} else {
	document.querySelector('body').classList.add('bg-secondary-light');
}
