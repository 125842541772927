<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: "Krushil's Portfolio",
			author: 'Krushil Shah',
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center">
		<div
			class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"
		>
			&copy; {{ copyrightDate }}
			<!-- <a
				href="https://github.com/krushilshah"
				target="__blank"
				class="font-general-medium hover:underline hover:text-teal	-600 dark:hover:text-teal	-300 duration-500"
			> -->
				<!-- {{ projectName }} -->
			<!-- </a> -->
			❤️
			<a
				href="https://krushilshah.tech"
				target="__blank"
				class="font-general-medium text-secondary-dark dark:text-secondary-light uppercase hover:underline hover:text-teal-600 dark:hover:text-teal-300 duration-500"
				>{{ author }}</a
			>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
