// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Restaurant Website ',
		category: 'WebSite',
		img: require('@/assets/images/web-project-2.jpg'),
	},	
];

export default projects;
