<script>
import AboutClientSingle from './AboutClientSingle.vue';

export default {
	props: ['techstack'],
	components: {
		AboutClientSingle,
	},
	data: () => {
		return {
			clientsHeading: 'Tech Stack',
			clients: [
				{
					id: 1,
					title: 'VueJs',
					img: require('@/assets/images/brands/vuejs-original-wordmark.svg'),
				},
				{
					id: 2,
					title: 'Laravel',
					img: require('@/assets/images/brands/laravel-plain-wordmark.svg'),
				},
				{
					id: 3,
					title: 'MySQL',
					img: require('@/assets/images/brands/mysql-original-wordmark.svg'),
				},
				{
					id: 4,
					title: 'Bootstrap',
					img: require('@/assets/images/brands/bootstrap-original-wordmark.svg'),
				},
				{
					id: 5,
					title: 'Html',
					img: require('@/assets/images/brands/html5-original-wordmark.svg'),
				},
				{
					id: 6,
					title: 'SASS',
					img: require('@/assets/images/brands/sass-original.svg'),
				},
				{
					id: 7,
					title: 'JavaScript',
					img: require('@/assets/images/brands/javascript-original.svg'),
				},
				{
					id: 7,
					title: 'Nuxt.js',
					img: require('@/assets/images/brands/nuxtjs-original-wordmark.svg'),
				},
				{
					id: 8,
					title: 'PhP',
					img: require('@/assets/images/brands/php-original.svg'),
				},
				{
					id: 9,
					title: 'PWA',
					img: require('@/assets/images/brands/pwa-icon.svg'),
				},
				{
					id: 10,
					title: 'React',
					img: require('@/assets/images/brands/react-original.svg'),
				},
				{
					id: 11,
					title: 'Git',
					img: require('@/assets/images/brands/github.png'),
				},
			],
		};
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20">
		<p
			class="font-general-medium text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
		>
			{{ clientsHeading }}
		</p>
		<div class="grid grid-cols-2 sm:grid-cols-4 mt-10 sm:mt-14 gap-2">
			<AboutClientSingle
				v-for="client in clients"
				:key="client.id"
				:client="client"
			/>
		</div>
		<p
			class="font-general-medium my-6 text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
		>
			Github Status
		</p>

		<div align="center"><img style="height: auto; width: 40%;" class="img"
				src="https://github-readme-stats.vercel.app/api/top-langs/?username=krushilshah&theme=radical&langs_count=8&layout=compact&hide_border=true" />
		</div>
	</div>
</template>
