export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://krushilshah.tech/',
	},
	{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/krushilshah',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/krushil-shah-39a548235/',
	},
	{
		id: 5,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://www.instagram.com/krushill.x/',
	},
];
